<template>
  <div class="header" id="header">
    <div class="header-wrap">
      <div class="header-logo">
        <div class="header-logo__wrap">
          <img src="../../assets/img/logo.png" alt="">
        </div>
      </div>
    </div>
    <div class="header-language">
      <tooltip-layout :maxWidth="160" position="left">
        <template slot="icon">
          <img :src="`/img/locales/${currentLanguage.urlPrefix}.png`" class="flag">
        </template>
        <ul>
          <li v-for="item in languages" :key="item.id" class="column">
            <a @click.prevent="changeLanguage(item)" :title="item.title" href="#"
               v-if="item.urlPrefix !== currentLanguage.urlPrefix">
                    <span class="language-item">
                      <img :alt="item.alt"
                           :src="`/img/locales/${item.urlPrefix}.png`"
                           class="flag"/>
                      <span class="language__text">{{ item.name }}</span>
                    </span>
            </a>
          </li>
        </ul>
      </tooltip-layout>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TooltipLayout from '@/components/atoms/TooltipLayout.vue'

export default {
  name: 'AppHeader',
  data () {
    return {}
  },
  components: {
    TooltipLayout
  },
  computed: {
    ...mapGetters([
      'languages',
      'currentLanguage'
    ])
  },
  methods: {
    changeLanguage (language) {
      this.$setLanguage(language.code)
      setTimeout(() => {
        window.location.reload()
      }, 800)
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  background: #00b764;
  transition: background $trans;
  z-index: 10;
  height: 145px;
  position: relative;
  @include respondTo(800px) {
    height: 60px;
  }
  &-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include respondTo(600px){
      justify-content: flex-start;
      padding-left: 30px;
    }
  }
  &-logo {
    &__wrap {
      display: block;
      width: 200px;
      cursor: pointer;
      @include respondTo(1200px) {
        width: 120px;
      }
      @include respondTo(800px) {
        width: 80px;
      }
      img {
        width: 100%;
        height: 100%;
        transition: transform $trans;
      }
      &:hover img {
        transform: scale(0.9);
      }
    }
  }
  &-language {
    position: absolute;
    right: 100px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
    @include respondTo(1000px){
      right: 80px;
    }
    @include respondTo(600px){
      right: 30px;
    }
  }
  &-lang{
    &__item{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
    &__name{
      margin-left: 10px;
    }
  }
}
</style>
